
import { camelizeKeys } from "humps"
import VueCompareImage from "vue-compare-image"

export default {
  components: {
    VueCompareImage,
  },
  layout: "widget",
  middleware: ["unauth"],
  async asyncData({ query, $config }) {
    const queryParams = camelizeKeys(query)

    return {
      beforeImg: `${$config.public.s3BucketURL}/${queryParams.cameraExid}/compares/${queryParams.compareExid}/start-${queryParams.before}.jpg`,
      afterImg: `${$config.public.s3BucketURL}/${queryParams.cameraExid}/compares/${queryParams.compareExid}/end-${queryParams.after}.jpg`,
    }
  },
}
